import React from 'react';
import Canva from '../components/canva';

export default function MiniSeries() {
  return (
    <Canva
      title="Miniseries"
      url="https://www.canva.com/design/DAGZYC-NyX4/LJAusoAytnLoFOppEPGnnA/view?embed"
    />
  );
}
